import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import Gallery from "../../components/gallery"
import SEO from "../../components/seo"

import styled from "styled-components"
import { colours, device } from "../../components/constants"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  text-align: center;

  img {
    border-radius: 5px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`

const CommercialPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Our Commercial Work Folio" />

      <h1>Commercial</h1>

      <Grid>
        {data.allMarkdownRemark.edges.map(({ node }, key) => (
          <section key={key}>
            <Link to={node.frontmatter.path}>
              <Img fluid={node.frontmatter.thumbnail.childImageSharp.fluid} />
              <h2>{node.frontmatter.title}</h2>
            </Link>
          </section>
        ))}
      </Grid>
    </Layout>
  )
}

export default CommercialPage

export const query = graphql`
  query CommercialAlbumsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "commercial" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 375, maxHeight: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
